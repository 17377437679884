import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPhone/Install/Others/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "iphone-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iphone-app-installationsassistent",
        "aria-label": "iphone app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPhone App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-others-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-others-models",
        "aria-label": "hinzufügen einer neuen ip kamera others models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`Others Models`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/81315/iPhone_Wizard_Installation_Others_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADKElEQVQ4y41U2W4jRRTtP+GRB14QL3wjDwgBEn8z0jDSBMbxBEcJYqKJndhtx726q7rWXqoPqtuL2ygIWjqqqlu3T909kEIhSzO0bYe6cXAOBL/3sjTNwbmAtTXiKEYz6HQdJl0Pxkqk2QlBxBpsswpCt+hchyhKCE3ToalbCFXjpWgQ8xZhZlHVDkmSYb1+wm63x3Yb0mPGNtjlNQLGDQpuwUsDIQyKQqIoBO1LkvV3Xo+XFcnjOMfLS4LDIaaVMQmlKtILylJjBOeaSDz8fkQ5yIuTQJoyhGGE3e6I/T6aSJOkIN3As/KBZE40P3sr4/iE6+s/CIvFDW5u7rBa3ePjx1s6L5crbDaht/BM9hpGwjQtsFzeEsnt7Z94//4Drq4WuL5e4e7uL5J//vx8Jpy7eOHuPx4QoqI4e1f3+5gs9w+O98H550vXXwvBfC9lRaCkzeSBz5pWFll6wuHQBzkMj4T+dQNR6gv3y5lFI/F4H3Dd4sgaJGWDhFdomgZt26JtHcE5B125qYyS5IQoyiimPrMPDxtsNruzy0IZRBlDziSSE4cQgkj81/l2QIeq7SBk75r34FNY4hAVePj0iDdv3uLduysqJ299IK1DrhyEbYHOkXXOtUTqCT2IcIiVt/B5nyGKC6wfn7C4D7G6XyOOMkpYwEqFl5QhyTkY4ygKBsYYpJRwriNiQy7bKfBJnE+J2IYJjsf87LIUBkr64PatN8e5VMaMGzCmkeWCVl5anE4SBVNUIX1S/qX+XoPPdq01GmNorZVGow0araFEX3r/2SmjZZ4sLwyWTxa/ry0Wmx4f1ha/PVpsYwMp9P8j9JOmUhqrrcXXPzl8+b3DNz87fPuLw1c/OHzxncOPv9aA1eO0mROM02dm4SD3xM+xwSbqsT4Oa2SQnnovAmo5bqYxNT9foifXUsPMMJ7FMP6CuUV8movqIsN9loe7cdhyDTZm1+sOekFZljBKIlMdcg1URkMpjbquobWGtRWstVBKQYoShe6QSKCpLYSQ1Ai+Zuu6IZ2gHbqidR0VdpqmyLIUnDOS013bTnvXdeClQEZ6GYqimO79+jdsj4jN7yf+xQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f196ee1d0c3c606d147c0bce2e438a9c/e4706/iPhone_Wizard_Installation_Others_d01.avif 230w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/d1af7/iPhone_Wizard_Installation_Others_d01.avif 460w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/7f308/iPhone_Wizard_Installation_Others_d01.avif 920w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/e1c99/iPhone_Wizard_Installation_Others_d01.avif 1380w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/6e1e4/iPhone_Wizard_Installation_Others_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f196ee1d0c3c606d147c0bce2e438a9c/a0b58/iPhone_Wizard_Installation_Others_d01.webp 230w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/bc10c/iPhone_Wizard_Installation_Others_d01.webp 460w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/966d8/iPhone_Wizard_Installation_Others_d01.webp 920w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/445df/iPhone_Wizard_Installation_Others_d01.webp 1380w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/87a8c/iPhone_Wizard_Installation_Others_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f196ee1d0c3c606d147c0bce2e438a9c/81c8e/iPhone_Wizard_Installation_Others_d01.png 230w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/08a84/iPhone_Wizard_Installation_Others_d01.png 460w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/c0255/iPhone_Wizard_Installation_Others_d01.png 920w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/b1001/iPhone_Wizard_Installation_Others_d01.png 1380w", "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/81315/iPhone_Wizard_Installation_Others_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f196ee1d0c3c606d147c0bce2e438a9c/c0255/iPhone_Wizard_Installation_Others_d01.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c4af085804478830c2f29a76c2e44f4/81315/iPhone_Wizard_Installation_Others_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNklEQVQ4y52Vyc8hURTFqwwfhZiHGBaWFv7/bS97owwLWyEhQSuzoqhyOud2V7WhKfkkJ56XvN87d3iXoqhBKKEoFEV5KU2LQW+1sFoaOBwOOJ1OOJ/PIsuyRIZhYDabQVFCEajxLBQ18BIY0zT8+NnD0gSs0xHr9QaDwUA0mUwEzIso5Z0zVVX/AGMaWnobC2MF0zzAtm0cj0dxSneO43h6C/QcxmLotNtYGoYAHj/X69WTB0yn0ygWi6J8Po9CoYBsNvs3hxq63S42mw3m8zkul4scpqNbmAdMJBIiOonH43fiHoG9Xg/r9Rr7/f4J8gTkwXfhSsidjgApXyDdBQIBrxC3coFt5nC5xGq1+m+oT0C3oq8cErhYLKRlfIE8QIeZTEaUy+VEvCQajd6FTJdsm7dAVpguK5UKSqWSqFarSW6TyaQAWeXtdiu999gqT8B6vS4wfjebTTQaDW+vXC57bWOapjyxj6sciUQkZPYhQ33M4W63k8b2BfJAKpWSsAmiuOYe13ToVnk6nfrn8PaluL+5ZmHcCwlky3yUQ4puboGEcc97y52OhMyn5+uQITFEVrdarYpYDAKZV7fKBHLK+Dr8+vqSgxRz5q55USgU8kJm23D2+Rblk/Gl67q0jDu+vgV0n6Pbh5zI2+0OF9uWPHKM8ftbA7al67DPFuYbCwsTuDoXHExT3PIiwtn4d8BgMIhwOCzi+t+flIZ+vy+HbcfB/JeB4XCI0WiE8XgseaU7uv0NFvBA6jtC+EUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c4af085804478830c2f29a76c2e44f4/e4706/iPhone_Wizard_Installation_Others_d02.avif 230w", "/en/static/1c4af085804478830c2f29a76c2e44f4/d1af7/iPhone_Wizard_Installation_Others_d02.avif 460w", "/en/static/1c4af085804478830c2f29a76c2e44f4/7f308/iPhone_Wizard_Installation_Others_d02.avif 920w", "/en/static/1c4af085804478830c2f29a76c2e44f4/e1c99/iPhone_Wizard_Installation_Others_d02.avif 1380w", "/en/static/1c4af085804478830c2f29a76c2e44f4/6e1e4/iPhone_Wizard_Installation_Others_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c4af085804478830c2f29a76c2e44f4/a0b58/iPhone_Wizard_Installation_Others_d02.webp 230w", "/en/static/1c4af085804478830c2f29a76c2e44f4/bc10c/iPhone_Wizard_Installation_Others_d02.webp 460w", "/en/static/1c4af085804478830c2f29a76c2e44f4/966d8/iPhone_Wizard_Installation_Others_d02.webp 920w", "/en/static/1c4af085804478830c2f29a76c2e44f4/445df/iPhone_Wizard_Installation_Others_d02.webp 1380w", "/en/static/1c4af085804478830c2f29a76c2e44f4/87a8c/iPhone_Wizard_Installation_Others_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c4af085804478830c2f29a76c2e44f4/81c8e/iPhone_Wizard_Installation_Others_d02.png 230w", "/en/static/1c4af085804478830c2f29a76c2e44f4/08a84/iPhone_Wizard_Installation_Others_d02.png 460w", "/en/static/1c4af085804478830c2f29a76c2e44f4/c0255/iPhone_Wizard_Installation_Others_d02.png 920w", "/en/static/1c4af085804478830c2f29a76c2e44f4/b1001/iPhone_Wizard_Installation_Others_d02.png 1380w", "/en/static/1c4af085804478830c2f29a76c2e44f4/81315/iPhone_Wizard_Installation_Others_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c4af085804478830c2f29a76c2e44f4/c0255/iPhone_Wizard_Installation_Others_d02.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8015FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/34db6a87859c3123bd66a6d58b629ec3/81315/iPhone_Wizard_Installation_Others_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADA0lEQVQ4y31Uy47bNhTVPwXoolnmB4u2SRb5ki66aAZBvqBJMBnEsi1b7wfFpyiKp7ikJXs6kzFwIPLy+NzDS14mQkg0dQO/LJhnB+c8FudhbRzXdQvGBCZjURYl5nmBXXkLNt4wjGiaDknez8jaCVnvwLVHXdUoyhrz7DFbBy4nnPsZJXOBZyYXErZtj8Mhiwmsg9YWh9YiGZgGoR/oq5DnFU6nEuOoI7gGGyNnYCbEONcYBoGuG8N4jREvGUeFCA3GFOp6CKAxYzF25ahLXG0J1/nKS0h1A1Pg3ARchW7WV9c3QuNtLDq8EqS0m+BV9KmTl5CsAxJI0xPO5wp53qBphk30/04oLsQUQOPbtWQl973A3d1n7PfnIJimWRBd3VGi4zHH4ZBjt8vw8LDH9+87/PhxwPFYhLUsKx4LRrEaXcdRFG0QjpkVpLJQesY0OQhB/BFtS4fHYYyFkAZCTrGG65ZJhETLsg2O6LRpXQiDlmmcagYhOIQQUEphmiYYYzBNBmcOFBzRIYmVZYf7+11w2rZjsJ9l5VYzKSW6pkbbNiiKAlVVgXMOKRWM1hi0B9M+ClJxqTbfvj2gaRiKoglOq6qPgqOGth7jBBiHZ3+j8eDGX2s4DDI4I6dUQ3K6njBtuWcc97sMaZajKHJkpwx1XYMLAS0F0t5jPyDWkFquGzR6ZkJ79UyHeXdpR+JIaaCkghSUIIJi1MMEoWw4uITaSwsFzM/AqbA2bBdXB7x02RMlFL5mGr9/tPjzzuL9p4i3dxa//WPx71FfRPWjnl7b8rbtwsW2SuHvrwa/vF3w67sFbz5EvH6/4NUfC/76YjArFV6jW3c/w3XL9oLpgsvcSHUReurq6Vyvh0IHQC6ik36I8/VQbusXbgVTm9snNbzNHP8sMTC5xejhjI/o43eTeOwZXjKOPNyjRnp0BpgnugoGzrlH7UUdIfiIXnnUCljcHGLe+9BF1s7hmyzLAoJbPPKiRJqmAefzGd5f1pxD5Dl4D1RNGzj7/R5ZlsFau/H+Ayi/jqH4YUH9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34db6a87859c3123bd66a6d58b629ec3/e4706/iPhone_Wizard_Installation_Others_d03.avif 230w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/d1af7/iPhone_Wizard_Installation_Others_d03.avif 460w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/7f308/iPhone_Wizard_Installation_Others_d03.avif 920w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/e1c99/iPhone_Wizard_Installation_Others_d03.avif 1380w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/6e1e4/iPhone_Wizard_Installation_Others_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/34db6a87859c3123bd66a6d58b629ec3/a0b58/iPhone_Wizard_Installation_Others_d03.webp 230w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/bc10c/iPhone_Wizard_Installation_Others_d03.webp 460w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/966d8/iPhone_Wizard_Installation_Others_d03.webp 920w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/445df/iPhone_Wizard_Installation_Others_d03.webp 1380w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/87a8c/iPhone_Wizard_Installation_Others_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34db6a87859c3123bd66a6d58b629ec3/81c8e/iPhone_Wizard_Installation_Others_d03.png 230w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/08a84/iPhone_Wizard_Installation_Others_d03.png 460w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/c0255/iPhone_Wizard_Installation_Others_d03.png 920w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/b1001/iPhone_Wizard_Installation_Others_d03.png 1380w", "/en/static/34db6a87859c3123bd66a6d58b629ec3/81315/iPhone_Wizard_Installation_Others_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/34db6a87859c3123bd66a6d58b629ec3/c0255/iPhone_Wizard_Installation_Others_d03.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Wählen Sie aus, ob Ihre Kamera über `}<strong parentName="li">{`WiFi`}</strong>{` angeschlossen werden soll oder ob Sie ein Ethernet-Kabel verwenden werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/81315/iPhone_Wizard_Installation_Others_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADXElEQVQ4y41Vu47kRBT1vxASkCCR8AlISJDBnyAEAZsRIARIhDyC/QLYXU2y0gYIZlbbM7093T3ds233w91uvx9VLpfLPuhW2Z6eYQOC47p176lT99Yt21ZRMAR+gLpuIKWCUq1Gb/t+iDQtUAmJo+fd4zUNBjtJMgRBBGsX13CjShOJ4Hm+hmoAVTfIWQU3ruGlCptAoKoU2haIogSbzW5IgJcS61DCStISccLheSEOhwC7nadBc0KWlUizEsRL8wphmML344FP8+MxQp4LZLmARQtKXuNwCHF768BxXKzXLmx7q4mc10Y05Qj8BNvtQXNoJKzXe6xWG3hehCQuYAVBivF4hul0idlsiel0gfn8FtfXC+2jWBjS+aQYXU4wHk81Li5GOD9/hfPzEa6uphiNXmO12sKK40JnNJ+/wcXFpQ68fHmlF9zcrHQGUZQPWdJI5R2PMVz3iCwzvj6uS2ZMoigqTexBfvJR7FSMRgJxKG7ExCBq9YTTXd6G09ipHcc5vEMw+K3eoNIJp+KnmT20e4RhDHfn6rWDIKX/ZungdmnrTtKZESFJ2H/KpOYsFyusHVfHI92w5O4M+13p7jn2DvZqo298mrJ7GfeCYZDCcXbYbvZdhtm9plm9QTv+/dc/mIwnCIIYWVboDhrBu7Mjgdl0gavRGN4h1NmdVtIJmjPcu75eQAEiPWwA8aKEw3H2WCxsuPsAQZjrtyjpm0K7G0eJLK+QZkLbsX4lO39mxPKMoxUckAKoK7Si1IDgKAvDsehBE1QcEAxt2S0SxkexpBPzwhJn1wJPXgs8nQg8m5jxj3GF2a4EyzmsinG8uBH4+FeFT35T+PyxwmePFT79XeGjXxSezwUEM8KXjsD7PzZ497sGH/zU4MOfG7z3fYN3vm3wwwupk7B64pdPJL56KvHoTOKbM4mvn0l88afEK9sI9qUHcYljZEAZ93baxe9Klh2qDt2cxNLhHOnqcBRvQd9c3eVEd+8huB6Tkw4bmHnSzZPB5v214cMXwziZRu/Lc4P/y7OyLEfJCgSsRaRvg0BZCiilwDlHVVUQQoAxKi1DzFv4HGhUrX1t24IxBilrPVpNQ/+SBqppsT94sG1bw3VdtG0XUwqGZ/4nxyDUHMdxsN1uIaUceP8CO4Z6CY48KnEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/e4706/iPhone_Wizard_Installation_Others_d04.avif 230w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/d1af7/iPhone_Wizard_Installation_Others_d04.avif 460w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/7f308/iPhone_Wizard_Installation_Others_d04.avif 920w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/e1c99/iPhone_Wizard_Installation_Others_d04.avif 1380w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/6e1e4/iPhone_Wizard_Installation_Others_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/a0b58/iPhone_Wizard_Installation_Others_d04.webp 230w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/bc10c/iPhone_Wizard_Installation_Others_d04.webp 460w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/966d8/iPhone_Wizard_Installation_Others_d04.webp 920w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/445df/iPhone_Wizard_Installation_Others_d04.webp 1380w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/87a8c/iPhone_Wizard_Installation_Others_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/81c8e/iPhone_Wizard_Installation_Others_d04.png 230w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/08a84/iPhone_Wizard_Installation_Others_d04.png 460w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/c0255/iPhone_Wizard_Installation_Others_d04.png 920w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/b1001/iPhone_Wizard_Installation_Others_d04.png 1380w", "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/81315/iPhone_Wizard_Installation_Others_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/31a3b9bfa67d8a2d977bb2ab9ac3f483/c0255/iPhone_Wizard_Installation_Others_d04.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5380d74e9fd65053b7763783e5691a64/81315/iPhone_Wizard_Installation_Others_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADf0lEQVQ4y41VW2skRRTuvycIgj646IPgrxBEFFZB/4R4ISz6vuiyqwhCdjeT7CSZTCaXzWR6pufS0/dLVVdXV/cn5/T0ZDau4MPHqT7nO1+dS0FbeS7gez6qqobWBsY0qOtme/a8AEmSo1Qaa9dFVZkdHrbnOE7h+yGseVhhEZYsSATX9bBarWEMYKoamSgxjyq4iYETlCh1jaYBwjDGbDZnMeIVSmPqa1hJWiJOFdbrEL4fYz534boBgiCB50VIswJpppCkCnFCvIj9q5WP5dLb8uI4R5aVsAI/gbeO4DgrzGYrJpFoizULxLHgBLpoNlvi9taB47Rx4k2nC84LwwzWeDzFixeHODw8xsFBHy9fHjHo++joBM+f92DbC0RxjqOjU/R6x1tOr9fn+KtXA+zvHzDPojYHgwtOPju7QL8/wOnpOfr9MxYdja7h+wnStMB4PGvjJ+ecc3x8hsFgxPzh8ApBkMLKMoX/QpJIFqJzZ2lOQug3eHcxBYuSCO2cxFaEFrF2Q0RRzt+dn5Y3vrGxWgVb/i64Qs+LcTuewp44mDsuD/iM2hiMeINdtWRpMTfXEziz5dbfgS60utupfxKmimhmU3uOubN6a4UXoytcnF9uZ/uGYHcgS2unJZF4nrez2k1oR5Nzyzevb5kvRIldDWs3IYoynhvdfH9+bZLid0fbpu3T07l+PYUfZIg3XBYMg5RbIUsiXdv3W6KZXV6O+ekMh5cYnAyx3xuiP7SRxDuCUSSw9hK46xhxUiCKJcJIsi9JCqSpZPBYIsE8z0+5qtE0w8hONnGeYYEil4ASaIocTSHRKAmUrY9ixCGIbOPXCo0qUKsCKAtAF9BScjFWKST+vlJ470eDD342+PiRwUeP2vO7Pxj8dalAHBIbzQt8uFfh/Z8qfPJLhU9/rfBgr8I73xvsHZYwhYRFFZzYCl/8rvHlE41vnml8/VTjqycan/+m0Z8orjLPJCZuwTHiffuHxnd/ajx8qvHZY41no/biu5b1BmXXVot/tfw2XiXvWqal0CGM70OyjbdvsBXt/IRolxdLpO07lJstds9DMDpflrX4vzwrTTMUIocvGoQKqEqFolAwxkBKibIsoZSCEDTHFJFs4EmgNhX7mqaBEAJaV2ytuqZ/SQ1TN1iuXEwmE8ZisUDTbGLGoOUZ/p+s/YA5tm3DcRxorbe8fwDy/HDjuxcFdwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5380d74e9fd65053b7763783e5691a64/e4706/iPhone_Wizard_Installation_Others_d05.avif 230w", "/en/static/5380d74e9fd65053b7763783e5691a64/d1af7/iPhone_Wizard_Installation_Others_d05.avif 460w", "/en/static/5380d74e9fd65053b7763783e5691a64/7f308/iPhone_Wizard_Installation_Others_d05.avif 920w", "/en/static/5380d74e9fd65053b7763783e5691a64/e1c99/iPhone_Wizard_Installation_Others_d05.avif 1380w", "/en/static/5380d74e9fd65053b7763783e5691a64/6e1e4/iPhone_Wizard_Installation_Others_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5380d74e9fd65053b7763783e5691a64/a0b58/iPhone_Wizard_Installation_Others_d05.webp 230w", "/en/static/5380d74e9fd65053b7763783e5691a64/bc10c/iPhone_Wizard_Installation_Others_d05.webp 460w", "/en/static/5380d74e9fd65053b7763783e5691a64/966d8/iPhone_Wizard_Installation_Others_d05.webp 920w", "/en/static/5380d74e9fd65053b7763783e5691a64/445df/iPhone_Wizard_Installation_Others_d05.webp 1380w", "/en/static/5380d74e9fd65053b7763783e5691a64/87a8c/iPhone_Wizard_Installation_Others_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5380d74e9fd65053b7763783e5691a64/81c8e/iPhone_Wizard_Installation_Others_d05.png 230w", "/en/static/5380d74e9fd65053b7763783e5691a64/08a84/iPhone_Wizard_Installation_Others_d05.png 460w", "/en/static/5380d74e9fd65053b7763783e5691a64/c0255/iPhone_Wizard_Installation_Others_d05.png 920w", "/en/static/5380d74e9fd65053b7763783e5691a64/b1001/iPhone_Wizard_Installation_Others_d05.png 1380w", "/en/static/5380d74e9fd65053b7763783e5691a64/81315/iPhone_Wizard_Installation_Others_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5380d74e9fd65053b7763783e5691a64/c0255/iPhone_Wizard_Installation_Others_d05.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Sobald Sie `}{`[den WPS-Modus aktiviert haben]`}{` (/Quick_Installation/Set_Up_A_Wireless_Connection/#plugplay-wps) sowohl auf Ihrer Kamera als auch auf dem Router klicken Sie auf `}<strong parentName="li">{`Search`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e69894d563e9776251cbed0b92d37c80/81315/iPhone_Wizard_Installation_Others_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPElEQVQ4y42Vy67cRBCG/SwsWLAIGxSRHRKPh0BEEVmQx0BcAkskpCQHZpg558xBuczYY3vGbrt9d7cv7R9V+ZJJhFAW/3R11dfVVd1tjVWWFSIRoesM2rZH3w+s2RYiRpaVaHSLMAje4YzBYqdpjiiSsDzZwZctgwQEgWD1PdB3BkXVwks6BFmPY6TRND2GAZAyxfHoLQXUqoUTt7CyXCNOKoShRBSlOJ8jnE4R20IkSJISeaGRZgpZ0SCOM/YHQcwszWltUWjmrCyr4fshbNuH45zYdt2Abdv22M7zGsRFIoXrnpmlkUTcfn9EEEikSQmLEqxWW2y3d1ivr/HixYq12dyyj2JjJTnW6xtstzvW1dV6YtfYbHa8dr93xwrzXLHGagU8L1x8s/+So/bCMOFu3ues2ZjHsmxYnOQCvkxMoqQLd7GhdZns/WCaVqzLTfnwp3kYiKnKtz6LdnFsH69e7iFE+k5yKYvFR6Ibj6KMR04YCgghOUZnzBXSjqeTwP6Nw8+B5rSANtjd/AP3eF4q87wAd7uXOJ9jns/Ph254Pk9u+eQLXD3/E+vVBmXZLjv6XohEFsuZ0Xt789rB0fGZoflh7+J6u8PROY0t089c0etXB04gZc5vjpJSovn8qJK/rlZ49sczhIFkbk48c+MtU0tlhyRTEFG+KEnnC6qR87kqhCKD64bw/AhhSF+WZB+/iLFChaqoYVSFQddAowCteKQ5xYghFTnFNYv4rip5JFaVI2eVRQ0nVPj5WuOXG42nk8j+6VrDDhSIoWRBrPDbrWb219uG9fS2wY9bjTtvLMzq6pqBT74xuPetwYPHBp8/Nvj0ocHHXxv8sG3Q1jXqssbftsZnjwzuPTT44nuDL58Y3P/O4KOvDB793gKaz3A8IyEVQqkQTCOJfHOc2qExSiYuHjWzc9zKpgXU1n9pvrT5wRf/x2VqvJRR442m0zxd7Plz/DBuafntJ1exZl9RjPpQzsrzAqoqEVUDpAa6RkMpjb7vUdc1mqaB1hpVRa3lSOoBogZM37FvGAZUVYW27Xi0jKH/EoPeDDidAxwOB5bv+xiGKdb3GLnx/ySMYmZs24brumjbduH+BREUeKeUYdleAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e69894d563e9776251cbed0b92d37c80/e4706/iPhone_Wizard_Installation_Others_d06.avif 230w", "/en/static/e69894d563e9776251cbed0b92d37c80/d1af7/iPhone_Wizard_Installation_Others_d06.avif 460w", "/en/static/e69894d563e9776251cbed0b92d37c80/7f308/iPhone_Wizard_Installation_Others_d06.avif 920w", "/en/static/e69894d563e9776251cbed0b92d37c80/e1c99/iPhone_Wizard_Installation_Others_d06.avif 1380w", "/en/static/e69894d563e9776251cbed0b92d37c80/6e1e4/iPhone_Wizard_Installation_Others_d06.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e69894d563e9776251cbed0b92d37c80/a0b58/iPhone_Wizard_Installation_Others_d06.webp 230w", "/en/static/e69894d563e9776251cbed0b92d37c80/bc10c/iPhone_Wizard_Installation_Others_d06.webp 460w", "/en/static/e69894d563e9776251cbed0b92d37c80/966d8/iPhone_Wizard_Installation_Others_d06.webp 920w", "/en/static/e69894d563e9776251cbed0b92d37c80/445df/iPhone_Wizard_Installation_Others_d06.webp 1380w", "/en/static/e69894d563e9776251cbed0b92d37c80/87a8c/iPhone_Wizard_Installation_Others_d06.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e69894d563e9776251cbed0b92d37c80/81c8e/iPhone_Wizard_Installation_Others_d06.png 230w", "/en/static/e69894d563e9776251cbed0b92d37c80/08a84/iPhone_Wizard_Installation_Others_d06.png 460w", "/en/static/e69894d563e9776251cbed0b92d37c80/c0255/iPhone_Wizard_Installation_Others_d06.png 920w", "/en/static/e69894d563e9776251cbed0b92d37c80/b1001/iPhone_Wizard_Installation_Others_d06.png 1380w", "/en/static/e69894d563e9776251cbed0b92d37c80/81315/iPhone_Wizard_Installation_Others_d06.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e69894d563e9776251cbed0b92d37c80/c0255/iPhone_Wizard_Installation_Others_d06.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[12]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0cd3b97650240205c76da2dc19949010/81315/iPhone_Wizard_Installation_Others_d07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgUlEQVQ4y5VVy24UMRCc3+HKgQsHPpEDEkJIXJEQUhD/wQVQiPawYaNkH7O7M7Pz8GMedqFqx5NJYEnWUsleT3d1td3tTcrygHSzhrUWxhi0bSuI681mg7wooJXCerW8Z9d13bjOsgxpmiK53mtc7xox7Psey+VS0HU92tbiUClcZwabwuJ3qlArg77vsN/vcXV1JaTWGDRKYbHTSHrn4QForVHXNQ6HA8qyRNM0Et15j2Fw6AS0hASnLe3oBx/2+8EhsdagqWsxIJRSYhQJvXMYhkEcuq6VfRIxcAR9GIQj4RldXFzIWXF47wVx7ZxDYwIhj6QoCkn38vISs9lM/LhHMUJI9qqqJBIV0YnnQnAtv/tBVOd5PiqJQaeDe8lqtRKF8/lcSDioKirlWnceWZbLLdImfovgkRBCGCO52w+jqluFA8mdg7WhlHj7DwmnAkbC1hoUeYY826EqC+x3KVqrx3R4GdvtNtzqkZTlDAEP03tUFjgYj1x7mesWKA1gez9eCM+b878UMiPOiagTQo/aQohLmQPxlJBOsYSmFTFVm9z98P8BpL1YGkydYOrRl4XP0uFeEiIdw110qiNR3ShUtYLS7CII2CFNE4o7wVPGkQuYZjC5lMe4vKig4zzzePnO4/mbML96D7x4Czx77fHhWyBPThHIC/tyDnz6AZydA19/AZ9/Ah+/A7PdExU+mvVkMJPkYU0dA9ORNnN/g0+g8yekfMpIWN0B4SGNjT7tz9gNo527b3evl1msRjfIlEdhgaGzMNaGV0br8T9GaQ3VVCi0x56PtOtljyO2pBR2jMKzWG9SLBYLAZ8190BJeKKA7S4TG/6n3NzcjH3M738AFZKzGiesCUEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cd3b97650240205c76da2dc19949010/e4706/iPhone_Wizard_Installation_Others_d07.avif 230w", "/en/static/0cd3b97650240205c76da2dc19949010/d1af7/iPhone_Wizard_Installation_Others_d07.avif 460w", "/en/static/0cd3b97650240205c76da2dc19949010/7f308/iPhone_Wizard_Installation_Others_d07.avif 920w", "/en/static/0cd3b97650240205c76da2dc19949010/e1c99/iPhone_Wizard_Installation_Others_d07.avif 1380w", "/en/static/0cd3b97650240205c76da2dc19949010/6e1e4/iPhone_Wizard_Installation_Others_d07.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0cd3b97650240205c76da2dc19949010/a0b58/iPhone_Wizard_Installation_Others_d07.webp 230w", "/en/static/0cd3b97650240205c76da2dc19949010/bc10c/iPhone_Wizard_Installation_Others_d07.webp 460w", "/en/static/0cd3b97650240205c76da2dc19949010/966d8/iPhone_Wizard_Installation_Others_d07.webp 920w", "/en/static/0cd3b97650240205c76da2dc19949010/445df/iPhone_Wizard_Installation_Others_d07.webp 1380w", "/en/static/0cd3b97650240205c76da2dc19949010/87a8c/iPhone_Wizard_Installation_Others_d07.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cd3b97650240205c76da2dc19949010/81c8e/iPhone_Wizard_Installation_Others_d07.png 230w", "/en/static/0cd3b97650240205c76da2dc19949010/08a84/iPhone_Wizard_Installation_Others_d07.png 460w", "/en/static/0cd3b97650240205c76da2dc19949010/c0255/iPhone_Wizard_Installation_Others_d07.png 920w", "/en/static/0cd3b97650240205c76da2dc19949010/b1001/iPhone_Wizard_Installation_Others_d07.png 1380w", "/en/static/0cd3b97650240205c76da2dc19949010/81315/iPhone_Wizard_Installation_Others_d07.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0cd3b97650240205c76da2dc19949010/c0255/iPhone_Wizard_Installation_Others_d07.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[13]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5591b5258783ed013cd7a95c8137b6f/81315/iPhone_Wizard_Installation_Others_d08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACsElEQVQ4y+2VS6skNRiG67e4c+FON+5cCLryh4k6woHZiAr+CFFwNc4g7gRROYfuc86c6q5rV1XqkqQql0eS6j7Tg0yjewMPuVTqzZsv4UsiREeRZ2itUUoxz3Pk1M7znKZt0WqmyAuMsSyLxVqPczy2u05Q1Q3JQ614qCdmrTHGkGUZ+/2eZVmYZ03Xj6QHRdlbds3CvDi8h7YVpOk+ihrj0NqQNobEWIf1IKWMDMNA3/eP/VCsdZRVR152lGVDURzIspqHh5yiaKiqjrruKMqWxDvHOI50XUfTNLRtG+tTO2wdPKLr2e8yDoeWvh8fCf0sK9jvc+r6QBIcFGXJdrvl9vaWm5sbrq+vY73ZbGIMQwnCIRR1XXNeTuNVVSGVJAnuwhaFEFhreVMJodjtdjRNy2IMznmc90gZBNe4T9NEElbcbLa8TPeo2WDCyVmPsR69WMxxkWkao+A8q+MS/giIro2C4aYkNhyTNyH0Z5POWU91miRVVVPWHaN2DMo+UtSCsqxRSq8x/DWFD7+Gj76BT75b+fhb+OAreHG/upml5reXM+9eed7+3PHeleP9p453njje+tRz9cyAlkHQc9d4vvjZ8+S55+kvK18+93z2zLM9rILjqCkOkp/+1Pzwh+bHM77/XfPXTjENksR7LpT1Ywj+MGiGXuKUxOvXCc7UKBFCrVt2nngYAXtk7fv4zfvVoRCSVija7p90QtH3chX0/k2sDs8F+15dJLm44f8F/5VgmHyJy4Lyvzk8dxruYRQ8IsQqIGL7FYlzjhUfE2nIOIHTWBAL9Ti+crT+PEVOY8OwkoS0pOTIYfK0GuyiUVpHwZCxT29MSA7j0NNKTyXBWRPHwoIhbS2LWdPXyY11niwvuLu7i4R0FMdfc2zjhS/rQ5xzf39Pmqbx/TnN/Rtl5JmZ0aKTowAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5591b5258783ed013cd7a95c8137b6f/e4706/iPhone_Wizard_Installation_Others_d08.avif 230w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/d1af7/iPhone_Wizard_Installation_Others_d08.avif 460w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/7f308/iPhone_Wizard_Installation_Others_d08.avif 920w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/e1c99/iPhone_Wizard_Installation_Others_d08.avif 1380w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/6e1e4/iPhone_Wizard_Installation_Others_d08.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5591b5258783ed013cd7a95c8137b6f/a0b58/iPhone_Wizard_Installation_Others_d08.webp 230w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/bc10c/iPhone_Wizard_Installation_Others_d08.webp 460w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/966d8/iPhone_Wizard_Installation_Others_d08.webp 920w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/445df/iPhone_Wizard_Installation_Others_d08.webp 1380w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/87a8c/iPhone_Wizard_Installation_Others_d08.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5591b5258783ed013cd7a95c8137b6f/81c8e/iPhone_Wizard_Installation_Others_d08.png 230w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/08a84/iPhone_Wizard_Installation_Others_d08.png 460w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/c0255/iPhone_Wizard_Installation_Others_d08.png 920w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/b1001/iPhone_Wizard_Installation_Others_d08.png 1380w", "/en/static/c5591b5258783ed013cd7a95c8137b6f/81315/iPhone_Wizard_Installation_Others_d08.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5591b5258783ed013cd7a95c8137b6f/c0255/iPhone_Wizard_Installation_Others_d08.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[15]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
      <li parentName="ul"><strong parentName="li">{`[16]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      